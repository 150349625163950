.custom-preview-img {
  min-height: 100px;
  background-color: rgb(243, 248, 253);
  position: relative;
}
.cancel-img {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 9;
}
.custom-input-file {
  display: none;
}
.custom-label-input,
.custom-label-input > label {
  cursor: pointer;
}
.custom-label-input {
  cursor: pointer;
  padding: 2px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-label-input:hover {
  background-color: rgb(243, 248, 253);
}
.custom-add-item-product {
  position: absolute !important;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  /* background: #fff !important; */
}

.custom-remove-item-product {
  position: absolute !important;
  top: 0;
  right: 0;
}
.custom-remove-item-product-img {
  position: absolute !important;
  top: -20px;
  right: -15px;
}
.custom-upload-list-images {
  border: 1px solid #ced4da;
  cursor: pointer;
  margin: 0;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom-upload-list-images:hover {
  background-color: rgb(243, 248, 253);
}
.custom-toatser > div {
  top: 72px !important;
}
.item-drag{
  cursor: pointer;
  width: 100%;
  height: 100%;

  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 3px;
  cursor: move;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-drag:hover{
  border: 1px dotted gray;
}

.custom-image-product{
  max-width: 100;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
}