@import url("../Zbase-style.css");

.container-header-cedi {
  min-height: 72px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.header-cedi {
  display: flex;
  max-width: 1440px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.container-logo {
  width: 100%;
}
.logo-cedi {
  position: relative;
}
.logo-top-cedi {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.8s;
}
.logo-true {
  transform: translateY(80%);
  /* transition-duration: 0.8s; */
}
.logo-true > svg {
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.logo-true > svg {
  padding: 0 100px;
}

.logo-true > svg > g {
  fill: #fff;
}
.logo-false {
  transform: translateY(0px);
  /* transition-duration: 0.8s; */
  /* opacity: 0.6; */
}

.logo-false > svg {
  width: 150px;
  height: 60px;
  /* transition-duration: 0.8s; */
}
.logo-false > svg > g {
  fill: #000;
  opacity: 1;
}
.header-highlighted {
  background-color: transparent;
  transition-duration: 0.8s;
}
.header-un-highlighted {
  background-color: #fff;
  transition-duration: 0.8s;
}
.action-header {
  display: flex;
  align-items: center;
  padding-right: 4rem;
  z-index: 1000;
}
.action-header > div:not(:last-child) {
  margin: 0 10px;
}
.action-header > div:last-child {
  margin-left: 10px;
  padding-right: 8px;
}
.action-header > div,
.logo-top-cedi > svg {
  cursor: pointer;
  transition-duration: 0.8s;
}
.action-MoreVertIcon {
  display: none;
}
.btn-search-custom {
  padding-left: 4rem !important;
  z-index: 99;
}
.highlights-search-product {
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  display: flex;
  justify-content: center;
}
.highlights-search-product > div{
  width: 90%;
}
.custom-thum-product{
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
}

@media only screen and (max-width: 1024px) {
  .action-header {
    padding-right: 64px;
  }
  .btn-search-custom {
    padding-left: 64px !important;
  }
}
@media only screen and (max-width: 768px) {
  .action-header {
    padding-right: 32px;
  }
  .action-MoreVertIcon {
    padding-right: 32px;
  }
  .btn-search-custom {
    padding-left: 32px !important;
  }
  .logo-false {
    justify-content: flex-end;
  }
  .logo-false > svg {
    margin-right: 75px;
  }
  .action-header {
    display: none;
  }
  .action-MoreVertIcon {
    display: flex;
  }
  .logo-top-cedi {
    justify-content: flex-end;
    transition-duration: 0.8s;
  }
  .logo-true > svg {
    padding: 0;
    /* transition-duration: unset; */
  }
}

@media only screen and (max-width: 470px) {
  .action-header {
    padding-right: 16px;
  }
  .action-MoreVertIcon {
    padding-right: 0;
  }
  .btn-search-custom {
    padding-left: 16px !important;
  }
  .inputBase-custom > input {
    width: 65%;
  }
  .logo-true {
    transform: translateY(5rem);
    /* transition-duration: 0.8s; */
  }
  .logo-false > svg {
    margin-right: 50px;
    width: 125px;
  }
  .logo-true > svg {
    /* padding: 0 30px; */
    /* transition-duration: unset; */
  }
}

@media only screen and (max-width: 375px) {
  .inputBase-custom > input {
    width: 50%;
  }
  .logo-false > svg {
    width: 100px;
  }
}
@media only screen and (max-width: 320px) {
  .logo-false > svg {
    width: 80px;
  }
}
