.container-navbar-admin{
    display: flex;
}
.container-navbar-admin .MuiDrawer-paper{
    height: calc(100vh - 72px) !important;
    top: 72px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.container-outlet-admin{
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    max-width: calc(100% - 241px);
    width: 100%;
}
.item-selected{
    background-color: #D3E3FD !important;
}