.container-blog {
  max-width: 1500px;
  margin: 0 auto 100px;
  overflow: hidden;
}

.container-items {
  column-count: 5;
  padding: 1rem;
}
.item {
  width: 100%;
  /* padding: 0 12px 20px; */
  position: relative;
  break-inside: avoid;
}
.item > article > img {
  width: 100%;
  height: 100%;
}
.blog-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.item > article > span > a {
  font-size: 10px;
  letter-spacing: 1.2px;
  font-variant-ligatures: none;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #999;
  padding-bottom: 2px;
}
.item > article > h2 {
  font-size: 20px;
  line-height: 24px;
  margin-top: 3px;
  margin-bottom: 15px;
  width: 100%;
  word-wrap: break-word;
  text-transform: initial;
  letter-spacing: inherit;
  font-variant-ligatures: none;
  color: inherit;
  font-style: normal;
  font-weight: 100;
}
.item > article > h2 > a {
  color: inherit;
  outline: 0 none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
  text-transform: initial;
  letter-spacing: inherit;
}
.banner-custom {
  text-align: center;
  padding: 45px 0;
}
.banner-title {
  display: flex;
  justify-content: center;
  color: #313131;
  display: block;
  text-transform: uppercase;
  font-style: normal;
  font-size: 4rem;
  letter-spacing: -0.03em;
  line-height: 4rem;
  margin: 0;
  font-weight: 200;
}
.custom-body-blog{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
}

@media only screen and (max-width: 1440px) {
  .container-blog {
    max-width: 1300px;
  }
  .container-items {
    column-count: 4;
  }
}
@media only screen and (max-width: 768px) {
  .container-items {
    column-count: 3;
  }
  .banner-custom {
    text-align: center;
    padding: 1rem 0;
  }
  .banner-title {
    font-size: 1rem;
    line-height: 1rem;
  }
}
@media only screen and (max-width: 470px) {
  .container-items {
    column-count: 1;
  }
}
