/* General styles */
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 35px;
  min-height: 500px;
}

.left-all,
.right-all {
  flex: 1;
}

.left-all {
  margin-left: 40px;
  max-width: 21%;
  padding-right: 8px;
  padding-left: 8px;
  border: 1px solid black;
  height: max-content;
  max-height: max-content;
  scrollbar-width: thin; /* Thin scrollbar */
}

.left-div {
  padding: 24px 20px;
}

.content-left-div {
  border: 1px solid black;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.content-left-div.open {
  max-height: 500px; /* Adjust this value to ensure it fits the content */
  opacity: 1;
  overflow: auto;
}

.middle-all {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.right-div {
  padding: 20px;
}

h4,
h5,
p {
  margin-bottom: 20px;
}

.select-size {
  padding: 10px;
  margin-bottom: 20px;
}

.add-button {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.match-with {
  width: 95%;
  margin: auto;
}

.match-with h3 {
  margin-bottom: -150px;
}

/* Drawer styles */
.MuiDrawer-paper {
  width: 382px;
}

/* Image gallery styles */
.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  width: 500px;
  height: 800px;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  transition: transform 0.3s ease; /* Smooth transition for the transform */
}

.thumbnail-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.thumbnail {
  width: 50px; /* Reduce width from 100px to 50px */
  height: 50px; /* Reduce height from 100px to 50px */
  margin: 5px;
  cursor: pointer;
  opacity: 0.5; /* Make the thumbnail images semi-transparent */
  transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
}

.thumbnail.active {
  opacity: 1; /* Fully opaque for the active thumbnail */
}

.button-content-df {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 8px;
}

.button-content-op {
  cursor: pointer;
  padding: 8px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: black;
}

/* Fullscreen image styles */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Màu nền với độ mờ */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Đảm bảo nó hiển thị trên cùng */
}

.fullscreen-overlay img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain; /* Đảm bảo ảnh không bị kéo dãn */
}

.fullscreen-overlay.active {
  opacity: 1;
  visibility: visible;
}
.product-info {
  border: 1px solid black;
}
.product-detail-info {
  padding: 24px;
  display: flex;
  flex-direction: column;
}
.product-detail-info-actions {
  padding: 24px;
}

.content-in-left {
  padding: 8px;
}
.title-content {
  padding: 8px 8px 0 8px;
}
.Button-Color {
  height: 23.5px;
  width: 23.5px;
  margin: 4px;
  position: relative;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-color {
  width: 4px;
  box-sizing: content-box;
}
.detail-color-selected {
  position: absolute;
  width: 4px;
  bottom: -4px;
  border-bottom: 1px solid #000;
}
.group-color {
  padding: 0 24px;
}
.product-size-info {
  padding: 4px 16px;
  background-color: transparent;
  display: flex;
  border: 1px solid #000;
  margin-right: 5px;
}
.size-button-active {
  color: white;
  background-color: black;
}
.group-size {
  padding: 24px;
}
.text-button {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.button-add-product {
  padding: 8px 12px;
  height: 100%;
  width: 100%;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #000;
  cursor: pointer;
  background-color: #fff;
}
.main-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .main {
    flex-direction: column; /* Đổi lại flex-direction cho thiết bị nhỏ hơn */
  }
  .main-mobile {
    display: block;
  }
  .left-all {
    display: none;
  }

  .middle-all {
    align-items: center; /* Đổi lại align-items */
    display: flex;
  }

  .match-with h3 {
    margin-bottom: -100px; /* Điều chỉnh margin-bottom */
  }

  .main-image {
    width: 100%; /* Đổi width */
    height: auto; /* Đổi height */
  }

  .thumbnail-list {
    width: 100%;
    flex-direction: column; /* Đổi lại flex-direction */
    justify-content: center; /* Đổi lại justify-content */
    margin-bottom: 200px;
  }
  .product-detail-info {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .product-detail-info-actions {
    padding: 10px 0 0 10px;
  }
  .group-size {
    padding: 10px;
  }
  .thumbnail {
    width: 30px; /* Điều chỉnh width */
    height: 30px; /* Điều chỉnh height */
  }

  .fullscreen-overlay img {
    max-width: 100%; /* Đổi lại max-width */
    max-height: 100%; /* Đổi lại max-height */
  }
  .match-with {
    display: none;
  }
  .footer-product-detail {
    display: none;
  }
  .main {
    display: none;
  }
  .right-all {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    background-color: #fff; /* Màu nền cho phần thông tin sản phẩm */
    z-index: 1000; /* Đảm bảo hiển thị trên top */
  }
}
