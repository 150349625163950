/* PurchasePolicy.css */
.policy-container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  line-height: 1.6;
}

.policy-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.policy-container h2 {
  color: #0056b3;
  margin-top: 20px;
  border-bottom: 2px solid #0056b3;
  padding-bottom: 5px;
}

.policy-container h3 {
  color: #333;
  margin-top: 15px;
  margin-bottom: 10px;
}

.policy-container p,
.policy-container ul {
  color: #666;
  margin-bottom: 10px;
}

.policy-container ul {
  padding-left: 20px;
}

.policy-container a {
  color: #f68800;
  text-decoration: none;
}

.policy-container a:hover {
  text-decoration: underline;
}

.policy-container li {
  margin-bottom: 5px;
}

.policy-container ul ul {
  list-style-type: circle;
  padding-left: 20px;
}

.policy-container ul ul li {
  margin-bottom: 3px;
}
