@import url("../Zbase-style.css");

/* #root-collection{
  height: 80vh;
  overflow: auto;
} */
#root-collection{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.container-section-img {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 100%;
}
.container-div-img {
  height: auto;
  position: relative;
}
.link-img {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.container-img-display,
.container-img-display-collection {
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  aspect-ratio: var(--ratio169);
  display: block;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.display-image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: transparent;
  object-fit: cover;
  transform: translateZ(0px);
  filter: brightness(0.9);
  scale: 1.2;
}

section:not(:first-child) {
  padding: 1rem;
}
.container-section-collection {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: #fff;
  height: auto;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
}
.figure-collection {
  background-color: #fff;
  margin: 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.container-img-display-collection {
  aspect-ratio: var(--ratio34);
}
.container-action-collection {
  position: absolute;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  /* background-color: gray; */
  bottom: 0px;
  height: 20vh;
  width: 100%;
  /* opacity: 0; */

  /* visibility: hidden;
  left: 0px;
  right: 0px; */
}
.temp-action{
  display: flex;
  flex-direction: column;
  position: relative;
}
.title-collection {
  color: #fff;
  font-size: 2rem;
  letter-spacing: -0.03em;
  line-height: 3rem;
  font-weight: 500;
}
.link-prod-from-collection {
  display: inline-block;
  width: auto;
  outline: none;
  padding: 16px 24px;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  /* opacity: 0; */
  transform: translateY(24px);
  transition: all 0.8s cubic-bezier(0.5, 0, 0, 1) 0.4s;
  pointer-events: auto;
  color: #FFF;
  letter-spacing: 0;
    line-height: 1rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-family: inherit;
    font-size: .75rem;
    font-weight: 700;
}
.link-prod-from-collection::before {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 2px;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.8s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  width: 100%;
  z-index: -1;
}

.title-collection, .link-prod-from-collection{
  transition-duration: 0.8s;
}

.display-image[data-visible="false"]{
  transition-duration: .8s;
  scale: 1;
}

.title-collection[data-visible="true"]{
  transform: translateY(200%);
  opacity: 0;
  transition-duration: .8s;
}

.title-collection[data-visible="false"]{
  transform: translateY(0);
  opacity: 1;
  transition-duration: .8s;
}

.link-prod-from-collection[data-visible="true"]{
  transform: translateY(200%);
  opacity: 0;
  transition-duration: 1s;
}
.link-prod-from-collection[data-visible="false"]{
  transform: translateY(20px);
  opacity: 1;
  transition-duration: 1s;
}

.pr-0-5{
  padding-right: 0.5rem !important;
}
.pl-0-5{
  padding-left: 0.5rem !important;
}

@media only screen and (max-width: 768px) {
  .container-section-collection{
    display: flex;
    flex-direction: column;
  }
  .title-collection {
    font-size: 1.5rem;
    letter-spacing: -0.04em;
    line-height: 2rem;
  }
}
