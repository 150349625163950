.custom-preview-img{
    min-height: 100px;
    background-color: rgb(243, 248, 253);
    position: relative;
}
.cancel-img{
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 9;
}
.custom-input-file{
    display: none;
}
.custom-label-input, .custom-label-input>label{
    cursor: pointer;
}
.custom-label-input{
    cursor: pointer;
    padding: 2px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-label-input:hover{
    background-color: rgb(243, 248, 253);
}