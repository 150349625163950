.container-img-detail-blogs {
  padding: 0 100px;
}

@media only screen and (max-width: 768px) {
  .container-img-detail-blogs {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 425px) {
  .container-img-detail-blogs {
    padding: 0 10px;
  }
}
.custom-display-des-post{
  white-space: pre-wrap;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}