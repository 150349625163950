.product-card-container {
  max-width: 354px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.name-styling {
  display: flex;
  overflow: hidden;
  height: 17px;
  width: 100%;
}

.name-styling a {
  text-decoration: none;
  color: black;
}

.name-styling a:hover {
  text-decoration: underline;
}

.flex-styling {
  font-size: 11px;
  padding: 24px 0px 24px;
  text-align: left;
}

.icon-styling {
  padding: 0px 3px 2px 2px;
}

.price-s {
  padding: 8px;
  width: 100%;
}

.border {
  border-width: 0px 0px 0.5px 0px;
  border-style: solid;
  border-color: black;
  width: 100%;
}
