.drawer-box {
  transition: transform 5s ease, opacity 5s ease; /* Tăng thời gian chuyển đổi */
}

.drawer-auto {
  width: auto;
  height: 100vh;
}

.drawer-fixed {
  height: 100vh;
}

.drawer-close {
  display: flex;
  justify-content: flex-start;
  padding: 8px;
}

.icon-white {
  color: rgb(13, 12, 12);
}

.drawer-paper {
  height: auto;
  box-shadow: var(--g-box-shadow, 0px 8px 24px 0px rgba(0, 0, 0, 0.1));
  transition: opacity 5s cubic-bezier(0.5, 0, 0, 1) 0s, transform 5s ease; /* Đồng bộ thời gian chuyển đổi */
  transform: translateY(100%);
  opacity: 0;
}

.drawer-paper.open {
  transform: translateY(0%);
  opacity: 1;
}

.drawer-backdrop {
  background-color: var(--g-color-backdrop, rgba(0, 0, 0, 0.5));
  transition: opacity 5s cubic-bezier(0.5, 0, 0, 1) 0s; /* Đồng bộ thời gian chuyển đổi */
  backdrop-filter: var(--g-blur, blur(15px));
  opacity: var(--_g-backdrop-opacity, 1);
  pointer-events: var(--_g-backdrop-pointer-events, auto);
  z-index: 1000;
}

.link-text {
  text-decoration: underline;
  color: inherit; /* Đảm bảo màu sắc của văn bản không đổi */
  font-weight: 700;
}

.drawer-close .icon-white {
  color: gray; /* Điều chỉnh màu sắc theo thiết kế của bạn */
}

.link-text .MuiListItemText-primary {
  font-weight: 700;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

@media (max-width: 600px) {
  .drawer-paper.open {
    width: 100%;
  }
}
