/* Checkout.css */
.layout-content {
  width: 90%;
  margin: auto;
}

.main-content {
  text-align: left;
  margin-top: 20px;
}

.delivery-group-head {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.delivery-group-head > div {
  border: 1px solid black;
  height: 100px;
  width: 100px;
  font-size: smaller;
}

.delivery-group-head > div > p {
  text-align: center;
  padding-top: 30px;
}

.delivery-group-head > div > div {
  text-align: center;
  margin-top: -15px;
  font-size: smaller;
}

.delivery-address {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: smaller;
  margin-top: 20px;
}

.delivery-address > span {
  width: 100%;
}

.delivery-address > input {
  width: 100%;
  padding: 8px;
  font-size: smaller;
}

.checkoutDeliverypro {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  gap: 20px;
  align-items: center;
}

.delivery-group > h2 {
  margin: 0 0 16px;
}

.delivery-group img {
  height: 150px;
  width: 100px;
}

.delivery-details {
  padding: 32px 0;
}

.delivery-details > div {
  display: flex;
  justify-content: space-between;
}

.delivery-details-address > div {
  font-size: smaller;
}

.delivery-free {
  font-size: smaller;
  padding-top: 20px;
}

.bottom-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: smaller;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px 20px 20px 65px;
}

.bottom-btn > p {
  padding-top: -10px;
  font-size: smaller;
}

.bottom-btn-link {
  font-size: small;
  padding: 15px;
  width: 200px;
  background: black;
  color: white;
}

.error-message {
  color: red;
  font-size: smaller;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .layout-content {
    width: 95%;
  }

  .bottom-btn {
    padding: 20px;
  }

  .delivery-group img {
    height: 120px;
    width: 80px;
  }
}

@media (max-width: 480px) {
  .layout-content {
      padding: 8px;
    width: 100%;
  }

  .delivery-group-head > div {
    width: 80px;
    height: 80px;
  }

  .checkoutDeliverypro {
    grid-template-columns: repeat(auto-fit, minmax(80px, max-content));
    gap: 10px;
  }

  .bottom-btn {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    padding: 10px;
  }

  .bottom-btn-link {
    width: 100%;
  }
}
