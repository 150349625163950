.text-11 {
  font-size: 11px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-15 {
  font-size: 15px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-17 {
  font-size: 17px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-19 {
  font-size: 19px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-21 {
  font-size: 21px !important;
}

.text-22 {
  font-size: 22px !important;
}

.text-23 {
  font-size: 23px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-25 {
  font-size: 25px !important;
}

.text-26 {
  font-size: 26px !important;
}

.text-27 {
  font-size: 27px !important;
}

.text-28 {
  font-size: 28px !important;
}
.d-flex {
  display: flex;
}
.text-bold {
  font-weight: 700;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.text-align-justify {
  text-align: justify;
}
.justify-space-between {
  display: flex;
  justify-content: space-between;
}
.justify-center {
  display: flex;
  justify-content: center;
}
