.container {
  padding-top: 140px;
  width: 90%;
  margin: auto;
  margin-bottom: 175px;
}

.heading {
  text-align: left;
  padding: 20px;
}

.heading > span {
  padding: 10px;
}

.shoping-cart-msg {
  color: #000000;
  background-color: #f2f2f2;
  padding: 10px 12px;
  width: 33%;
  font-size: smaller;
  text-align: left;
  margin-bottom: 20px;
}

.cart-item-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.cart-item {
  height: 550px;
}

.cart-item-header {
  font-size: small;
  text-align: left;
}

.cart-item-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item-container > div {
  border: 1px solid black;
}

.cart-item-container > div > img {
  object-fit: cover;
  height: 400px;
  width: 100%;
}

.cart-item-details {
  display: flex;
  font-size: smaller;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
}

.cart-item-details > div > button {
  background-color: white;
  border: none;
}

.cart-item-description > div {
  font-size: 11px;
}

.cart-item-description > div > span {
  padding: 10px;
}

.bottom-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px 20px 20px 65px;
}

.bottom-btn > p {
  font-size: 0.6rem;
  text-align: right;
}

.checkout-btn {
  font-size: small;
  padding: 15px;
  width: 200px;
  background: black;
  color: white;
}

/* Responsive design */
@media (max-width: 1024px) {
  .cart-item-flex {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .shoping-cart-msg {
    width: 100%;
    font-size: smaller;
  }

  .cart-item-flex {
    grid-template-columns: 1fr;
  }

  .cart-item-container {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-container > div {
    width: 100%;
  }

  .cart-item-container > div > img {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }

  .bottom-btn {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
  }

  .checkout-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .heading > span {
    font-size: 1rem;
  }

  .shoping-cart-msg {
    font-size: x-small;
  }

  .cart-item {
    height: auto;
  }

  .cart-item-header {
    font-size: smaller;
  }

  .cart-item-description > div {
    font-size: x-small;
  }
}
