@media (max-width: 600px) {
  .MuiCardContent-root {
    padding: 8px !important;
  }
  .MuiTypography-root {
    font-size: 0.875rem !important;
  }
  .MuiTableCell-root {
    padding: 4px 8px !important;
  }
  .contentCart {
    display: flex;
    flex-direction: column !important;
  }
  .cart {
    display: flex;
    width: 100% !important;
  }
}

.MuiTypography-subtitle1 {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.MuiTypography-body2 {
  font-size: 0.875rem !important;
}

.MuiTypography-subtitle2 {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.MuiTableContainer-root {
  margin-top: 16px !important;
}

.MuiCard-root {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.MuiDivider-root {
  margin: 8px 0 !important;
}
.contentCart {
  display: flex;
  flex-direction: row;
}
.cart{
  width: 30%;
}