/* Import base styles */
@import url("../Zbase-style.css");

/* General footer styles */
.footer {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-footer {
  display: flex;
  flex-direction: row;
  padding: 5rem 4rem;
  max-width: 1440px;
  margin: 0 auto;
}

.footer-map,
.footer_detail {
  padding: 1rem;
}

.footer_detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer_detail_detail {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 300px;
}

.footer_detail_detail > p {
  margin: 10px 0;
}

.footer_bottom {
  text-align: center;
  padding: 1rem;
}

/* Responsive design */
@media (max-width: 1024px) {
  .container-footer {
    flex-direction: column;
    padding: 2rem 2rem;
  }

  .footer-map,
  .footer_detail {
    width: 100%;
    padding: 1rem 0;
  }

  .footer_detail {
    flex-direction: column;
    align-items: center;
  }

  .footer_detail_detail {
    width: 100%;
    max-width: none;
    padding: 1rem 0;
  }
}

@media (max-width: 768px) {
  .container-footer {
    padding: 1rem 1rem;
  }

  .footer-map iframe {
    height: 300px; /* Adjust the height for smaller screens */
  }
}

@media (max-width: 480px) {
  .footer-map iframe {
    height: 200px; /* Further reduce the height for mobile screens */
  }

  .footer_detail_detail {
    padding: 0.5rem 0;
  }
}

/* Logo responsiveness */
.footer_bottom img {
  width: 100%;
  max-width: 800px; /* Adjust the max-width as needed */
  height: auto;
}

.footer_bottom {
  padding: 3rem;
}
@media (max-width: 768px) {
  .footer_bottom img {
    max-width: 550px; /* Reduce size further for smaller screens */
  }
  .footer_bottom {
    padding: 2rem;
  }
}

@media (max-width: 480px) {
  .footer_bottom img {
    max-width: 250px; /* Further reduce size for mobile screens */
  }
  .footer_bottom {
    padding: 1rem;
  }
}

.footer_bottom > img > g {
  fill: #fff;
}
